import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

/**
 * SmartText is a customizable text input component with suggestions.
 */
const SmartText = (props) => {
  const {
    id,
    questionList,
    value,
    setProps,
    textareaStyle,
    suggestionsContainerStyle,
    suggestionButtonStyle,
    suggestionButtonSelectedStyle,
    clearButtonStyle,
  } = props;

  const [suggestions, setSuggestions] = React.useState([]);
  const [showSuggestions, setShowSuggestions] = React.useState(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = React.useState(-1);
  const textareaRef = useRef(null);
  const suggestionsContainerRef = useRef(null);
  const suggestionRefs = useRef([]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        textareaRef.current &&
        !textareaRef.current.contains(event.target) &&
        suggestionsContainerRef.current &&
        !suggestionsContainerRef.current.contains(event.target)
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (value) {
      const filteredSuggestions = questionList.filter(item =>
        item.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
      setShowSuggestions(filteredSuggestions.length > 0);
    } else {
      setSuggestions(questionList);
      setShowSuggestions(false);
    }
  }, [value, questionList]);

  useEffect(() => {
    const handleResize = () => {
      if (textareaRef.current && suggestionsContainerRef.current) {
        suggestionsContainerRef.current.style.width = value ? '100%' : 'inherit';
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set the initial width

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [value]);

  useEffect(() => {
    if (selectedSuggestionIndex >= 0 && suggestionRefs.current[selectedSuggestionIndex]) {
      suggestionRefs.current[selectedSuggestionIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [selectedSuggestionIndex]);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'; // Reset height to auto to measure content height
      const newHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = `${newHeight}px`; // Apply the new height
    }
  };

  const handleTextareaChange = (event) => {
    const newValue = event.target.value;
    setProps({ value: newValue });
    setSelectedSuggestionIndex(-1);
    adjustTextareaHeight();
  };

  const handleClearClick = () => {
    setProps({ value: '' });
    setShowSuggestions(false);
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'; // Reset the height to auto on clear
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown') {
      setSelectedSuggestionIndex((prevIndex) =>
        Math.min(prevIndex + 1, suggestions.length - 1)
      );
    }
    if (event.key === 'ArrowUp') {
      setSelectedSuggestionIndex((prevIndex) =>
        Math.max(prevIndex - 1, 0)
      );
    }
    if (event.key === 'Enter' && selectedSuggestionIndex >= 0) {
      const selectedSuggestion = suggestions[selectedSuggestionIndex];
      setProps({ value: selectedSuggestion });
      setShowSuggestions(false);
      adjustTextareaHeight();
    }
  };

  return (
    <div className="g-0 ms-auto flex-nowrap mt-3 mt-md-0" id={id || 'searchbox'}>
      <div style={{ position: 'relative' }}>
        <textarea
          ref={textareaRef}
          id={id}
          placeholder="Ask Questions Naturally..."
          className="pro-contact-form-message combo w-input"
          style={{ 
            ...textareaStyle, 
            height: 'auto',
            outline: 'none', 
          }}
          value={value}
          onChange={handleTextareaChange}
          onKeyDown={handleKeyDown}
          onClick={() => setShowSuggestions(true)}
        />
        {value && (
          <button
            onClick={handleClearClick}
            style={{
              position: 'absolute',
              right: '4rem',
              top: '10px',
              backgroundColor: '#616161',
              border: '2px solid #616161',
              fontSize: '20px',
              cursor: 'pointer',
              width: '24px',
              height: '24px',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 0,
              color: 'white',
              ...clearButtonStyle,
            }}
          >
            ×
          </button>
        )}
        <div
          ref={suggestionsContainerRef}
          id="suggestions-container"
          style={{
            ...suggestionsContainerStyle,
            width: value ? '100%' : 'inherit',
            display: showSuggestions && suggestions.length > 0 ? 'block' : 'none',
          }}
        >
          {suggestions.map((suggestion, index) => (
            <button
              key={index}
              ref={(el) => (suggestionRefs.current[index] = el)}
              className={`suggestion ${selectedSuggestionIndex === index ? 'selected' : ''}`}
              style={{
                ...suggestionButtonStyle,
                ...(selectedSuggestionIndex === index ? suggestionButtonSelectedStyle : {}),
              }}
              onClick={() => {
                setProps({ value: suggestion });
                setTimeout(() => {
                  setShowSuggestions(false);
                }, 0);
                adjustTextareaHeight();
              }}
            >
              {suggestion}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

SmartText.defaultProps = {
  id: '',
  textareaStyle: {},
  suggestionsContainerStyle: {},
  suggestionButtonStyle: {},
  suggestionButtonSelectedStyle: {},
  setProps: () => {},
  clearButtonStyle: {
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '16px',
    cursor: 'pointer',
  },
};

SmartText.propTypes = {
  /**
 * The id for the SmartText component.
 */
  id: PropTypes.string,
  
  /**
   * The list of questions or suggestions that will be displayed as the user types.
   */
  questionList: PropTypes.arrayOf(PropTypes.string).isRequired,
  
  /**
   * The current value of the textarea.
   */
  value: PropTypes.string,
  
  /**
   * A function that updates the component's props, used to sync the state with Dash.
   */
  setProps: PropTypes.func,
  
  /**
   * Custom styles for the textarea.
   */
  textareaStyle: PropTypes.object,
  
  /**
   * Custom styles for the suggestions container.
   */
  suggestionsContainerStyle: PropTypes.object,
  
  /**
   * Custom styles for each suggestion button.
   */
  suggestionButtonStyle: PropTypes.object,
  
  /**
   * Custom styles for the selected suggestion button.
   */
  suggestionButtonSelectedStyle: PropTypes.object,
  
  /**
   * Custom styles for the clear button.
   */
  clearButtonStyle: PropTypes.object,
};

export default SmartText;
